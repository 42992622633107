import React from 'react'
import {graphql, Link} from 'gatsby'
import {MDXProvider} from '@mdx-js/react'
import {MDXRenderer} from 'gatsby-plugin-mdx'
import Layout from 'components/layout'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import {usePlausible, localizedPaths} from 'utils'

export default function ToolPdp({
  data: {mdx, videos},
  pageContext,
  ...pageProps
}) {
  const {title, subtitle, links, cover} = mdx.frontmatter
  const page = {
    title: `Tools > ${title}`,
  }

  const meta = {
    title: `Inhabit Tools: ${title}`,
    description: `Download tools and resources for living, fighting, and getting organized.`,
    image: cover.publicURL,
  }

  // mutate pageContext
  pageContext.lang = 'en'
  pageContext.localizedPaths = localizedPaths

  const components = {
    a: (props) => <a className="underline hover:text-orange" {...props} />,
  }

  const video = videos.nodes.find((file) => {
    return pageContext.slug.includes(file.relativePath.replace('.mp4', ''))
  })

  return (
    <Layout pageInfo={page} {...pageProps} pageContext={pageContext} seo={meta}>
      <Link
        to="/tools"
        className="inline-block m-6 pt-3 pb-1 px-3 uppercase text-orange hover:bg-olive hover:text-white"
      >
        Back
        <br />↵
      </Link>
      <main>
        <div className="px-4 text-center text-orange max-w-lg mx-auto mb-12">
          <h1 className="text-4xl font-sans">{`${title}${
            subtitle ? `: ${subtitle}` : ''
          }`}</h1>
        </div>
        <div className="px-4 mx-auto max-w-2xl min-h-[100vw] sm:min-h-[32rem]">
          {video ? (
            <video
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              className="w-full"
            >
              <source src={video.publicURL} type="video/mp4" />
            </video>
          ) : (
            <div className="bg-black p-8 sm:p-20">
              <GatsbyImage image={getImage(cover)} alt="" />
            </div>
          )}
        </div>
        <div className="my-12">
          {links.map((link) => (
            <a
              key={link.label}
              href={link.file || link.url}
              download={link.file ? true : false}
              className="btn-orange my-4"
              onClick={() => {
                usePlausible('tools.download', {name: link.file || link.url})
              }}
            >
              {link.label}
            </a>
          ))}
        </div>
        <div className="max-w-2xl px-4 sm:px-0 m-auto text-2xl text-olive">
          <MDXProvider components={components}>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query MdxPDPQuery($id: String) {
    videos: allFile(filter: {relativePath: {glob: "tools/*.mp4"}}) {
      nodes {
        id
        relativePath
        publicURL
      }
    }
    mdx(id: {eq: $id}) {
      id
      body
      frontmatter {
        title
        subtitle
        cover {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        links {
          label
          file
          url
        }
      }
    }
  }
`
